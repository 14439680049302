.ant-table-thead .ant-table-cell {
  background-color: rgb(157 106 161) !important;
  text-align: center !important;
}

.status-parent {
  display: inline;
  padding: 0;
}

.ant-pagination-item-active {
  border-color: rgb(157 106 161) !important;
  border: 2px;

  color: black;
  background-color: rgba(157, 106, 161, 0.56) !important;
  border-radius: 6px;
}

:where(.css-dev-only-do-not-override-1gwfwyx) a {
  color: #000;
}

:where(.css-dev-only-do-not-override-1gwfwyx).ant-pagination
  .ant-pagination-item-active
  a {
  color: black;
  background-color: rgb(157 106 161) !important;
  border-radius: 6px;
}
.ant-table-filter-trigger .ant-btn-primary {
  background-color: rgb(157 106 161) !important;
}

.ant-btn-primary {
  color: #fff;
  background: rgb(157 106 161);
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.ant-btn-primary:hover {
  background-color: rgb(157 106 161) !important;
}

.ant-btn-link {
  color: black;
}
.ant-checkbox-checked .ant-checkbox-inner .ant-checkbox-inner::after {
  color: rgb(157 106 161);
}

.ant-checkbox-input {
  background-color: rgb(157 106 161);
}
.ant-table-filter-trigger.active {
  color: black;
}
:where(.css-dev-only-do-not-override-1gwfwyx).ant-spin .ant-spin-dot-item {
  color: rgb(157 106 161) !important;
}
.ant-table {
  background-color: transparent;
}
.ant-table-body {
  background-color: transparent;
}

.ant-picker-clear {
  visibility: visible !important;
}

.ant-picker-clear:hover {
  opacity: 1;
}
