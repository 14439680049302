:where(
    .css-dev-only-do-not-override-1gwfwyx
  ).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  border-color: rgb(157 106 161);
}
.ant-radio-button-wrapper {
  transition: none !important;
}

:where(.css-dev-only-do-not-override-1gwfwyx).ant-radio-button-wrapper:hover {
  color: black;
  border: none;
}
